import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { Homepage } from './component/pages/Homepage';
import { Contentpage } from './component/pages/Content';
import { Detailspage } from './component/pages/Details';
import { Authorpage } from './component/pages/Author';
import { Notfound } from './component/pages/Notfound';
import { SessionData } from './component/pages/SessionData';
import { SessionList } from './component/pages/SessionList';

import { Routes } from './routes/routes';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function App() {
  return (
    <>
    <Switch>
      <Routes exact path = "/" component = {Homepage} />
      <Routes exact path="/irim3d/:year" component = {Contentpage} />
      <Routes exact path = "/author/irim3d/:year" component = {Authorpage} />
      <Routes exact path = "/content/details/:year/:id" component = {Detailspage} />
      <Routes exact path = "/content/details/:year/:name" component = {Detailspage} />
      <Routes exact path = "/session/:year/:name" component = {SessionData} />
      <Routes exact path = "/content/list/session/:year" component = {SessionList} />
     
      <Route exact path = "/404" component = {Notfound} />  
      <Redirect to = "/404" />
    </Switch>
    </>
  );
}

export default App;
