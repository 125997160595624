import React from 'react'
import {Pdfseparator,PdfseparatorOnly} from './Pdfseparator'
import {Commaseparator} from './Commaseparator'

export const Searchdata = (props) => {
	var data = props.data;
	var id = props.id;
	const found = search(id,data);
	return(

		<>
			<h3>{found != undefined ? found.title : ''}</h3> 
			<i>{found != undefined ? found.creators : ''}</i><br /> 
			<p>  
				<b>Abstract:</b>  
			</p>
			{found != undefined ? <div style={{textAlign: 'justify'}} dangerouslySetInnerHTML={{__html: found.description}}></div> : ''}
			<p>{found != undefined ? 
				
				<>{found.keywords !== '' ? <><b>Keywords:</b>{found.keywords}</> : ''}</> 

				: ''}</p>
			<p><b>DOI:</b> {found != undefined ? <a href={"https://zenodo.org/record/"+found.rec_id}>{found.doi}</a> : ''}</p>
			
			<p>   
		    	{found != undefined ?<><b>Download:</b><PdfseparatorOnly file = {found.files} /></> : '' } 
			</p>
			<p>  
				<b>BibTeX:</b>  
		    </p>
			<pre>
		  		{found != undefined ? found.bibtext : ''}
		  	</pre>
		  	
			{found != undefined ? <Commaseparator file = {found.notes} /> : ''}
		</>
	)
}

export const SearchListdata = (props) => {
	var data_1 = props.data;
	var name_1 = props.name;
	const foundbyName = searchbyName(name_1,data_1);
	return(
		<>

		{foundbyName.length !=0 && foundbyName.map((contentDetails, index) => (
	          <tr key={index}>
	            <td width={500}>
	              <a href={"/content/details/"+contentDetails.year+"/"+contentDetails.rec_id}>{contentDetails.title}</a>
	              <br />
	              <i>
	                {contentDetails.creators}
	              </i><br />
	            </td>
	            <td valign="top">
	            <Pdfseparator file = {contentDetails.pdf}  />
	            </td>
	          </tr> 
	      ))}
      </>
	)
}

var search = function(nameKey, myArray){
    for (var i=0; i < myArray.length; i++) {
        if (myArray[i].rec_id === nameKey) 
        {
	        return myArray[i];
	    }
	}
}

var searchbyName = function(nameKey, myArray){
	var items = myArray.filter(item => item.conference_session == nameKey);
	return items
}