import React, { Component } from 'react';  
import { Route,Redirect } from 'react-router-dom';  

import {Sidebar} from '../component/includes/Sidebar';
export const Wrapper = ({children}) => {  
  return (  
    <>
  		<Sidebar pathname={children.props.location.pathname} year = {children.props.match.params.year} name = {children.props.match.params.name}  />
  		<div className="content">
		    <a href="/"><img src={process.env.PUBLIC_URL+"/logo.png"} /></a><br />
		    {children}
	    </div>
	   
    </>
  )  
}  

