import React,{useEffect,useState} from 'react';
import {Spinner} from '../spinner/Spinner';
import Papa from 'papaparse'
import {Searchdata} from '../utils/Searchdata'

export const Detailspage = (props) =>{
	const [dataDetails,SetDataDetails] = useState([]);
	const [metaDetails,SetMetaDetails] = useState([]);
	const [status,SetStatus] = useState(true);
	const [excelData,SetExcelData] = useState([])

	 useEffect(() => {
	    var csvFilePath = process.env.PUBLIC_URL+`/${props.match.params.year}.csv`;
	      Papa.parse(csvFilePath, {
	        header: true,
	        download: true,
	        skipEmptyLines: true,
	        complete: updateData
	      });

	      function updateData(result) {
	        const data = result.data;
	        SetExcelData(data);
	        SetStatus(false)
	      }
	  },[])

	return(
		<>
		 <Spinner visible = {status} />
		  <h1>{props.match.params.year} I-RIM Conference</h1>
		  <Searchdata data = {excelData} id = {props.match.params.id} />
		  
		</>

	)
}

