import React,{useEffect,useState} from 'react'
import {Spinner} from '../spinner/Spinner';
import {Pdfseparator} from '../utils/Pdfseparator'
import Papa from 'papaparse'

export const Authorpage = (props) => {
  const [data,SetData] = useState([]);
  const [excelData,SetExcelData] = useState([])
  const [status,SetStatus] = useState(true);

  useEffect(() => {

    var csvFilePath = process.env.PUBLIC_URL+`/${props.match.params.year}-authors.csv`;
      Papa.parse(csvFilePath, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: updateData
      });

      function updateData(result) {
        const data = result.data;
        SetExcelData(data);
      }
  },[props.match.params.year])

	return(
		<>
			<Spinner visible = {status} />
      <h1>{props.match.params.year} I-RIM Conference</h1>  
      <h2>By Author</h2>  
      <table>  
        <tbody>
          <tr>
            <td>&nbsp;</td>
          </tr>
          {excelData.map((contentDetails, index) => (
            <tr key={index}>
              <td width={200}>{contentDetails.Author}</td>
              <td>
                <Pdfseparator file = {contentDetails.files} pageno = {contentDetails.pageNum} recID = {contentDetails.rec_id} year = {props.match.params.year} />
              </td>
            </tr>
          ))}
        </tbody>
      </table> 
      <br />
		</>
	)
}