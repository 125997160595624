import React,{useEffect,useState} from 'react'
import {NavLink} from 'react-router-dom'
export const Sidebar = (props) =>{
	return(
		<>
		  <div className="menu">
		    <ul>
		      <li className="here"><NavLink exact className="menu" to="/">Home</NavLink></li>
			  <li className="label"><NavLink exact className="menu" to="/irim3d/2023">2023 I-RIM Conference</NavLink></li>
			  <li className="menu"><NavLink exact className="menu" to="/author/irim3d/2023">By Author</NavLink></li>
			  <li className="label"><NavLink exact className="menu" to="/irim3d/2022">2022 I-RIM Conference</NavLink></li>
			  <li className="menu"><NavLink exact className="menu" to="/author/irim3d/2022">By Author</NavLink></li>
			  <li className="label"><NavLink exact className="menu" to="/irim3d/2021">2021 I-RIM Conference</NavLink></li>
		      <li className="menu">
			  </li>
		      <li className="menu"><NavLink exact className="menu" to="/author/irim3d/2021">By Author</NavLink></li>

		      <li className="label"><NavLink exact className="menu" to="/irim3d/2020">2020 I-RIM Conference</NavLink></li>
		      <li className="menu">
	      		<NavLink exact className="menu" to="/content/list/session/2020">By Session</NavLink>
			  </li>
		      <li className="menu"><NavLink exact className="menu" to="/author/irim3d/2020">By Author</NavLink></li>

		      <li className="label"><NavLink exact className="menu" to="/irim3d/2019">2019 I-RIM Conference</NavLink></li>
		      <li className="menu">
	      		<NavLink exact className="menu" to="/content/list/session/2019">By Session</NavLink>
			  </li>
		      <li className="menu"><NavLink exact className="menu" to="/author/irim3d/2019">By Author</NavLink></li>
		      
		      </ul>
  		</div>

	    </>


	)
}