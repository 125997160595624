import React,{useEffect,useState} from 'react'
import {Spinner} from '../spinner/Spinner';
import {Removeduplicate} from '../utils/Removeduplicate'
import Papa from 'papaparse';


export const SessionList = (props) => {
  const [excelData,SetExcelData] = useState([])

  useEffect(()=>{

    var csvFilePath = process.env.PUBLIC_URL+`/${props.match.params.year}.csv`;
      Papa.parse(csvFilePath, {
        header: true,
        download: true,
        skipEmptyLines: true,
        complete: updateData
      });

      function updateData(result) {
        const data = result.data;
        SetExcelData(data);
      }
  },[props.match.params.year])

	return(
		<>
    
    <h1>{props.match.params.year} I-RIM Conference</h1>
    <h2>By Session</h2>
			<table>  
        <tbody>

          <Removeduplicate file = {excelData} year = {props.match.params.year} />
    
        </tbody>
      </table>
      
      <br />
		</>
	)
}