import React,{FC} from "react";
import { Route, RouteComponentProps, RouteProps, Redirect } from "react-router";

import { Wrapper } from "../wrapper/Wrapper";

export const Routes : FC<RouteProps> = ({ component: Component, ...rest }) => {
	
	return (
	  <>
      <Route
        {...rest}
            render={(props: RouteComponentProps<{}>) => <Wrapper><Component {...props} /></Wrapper> }
      />
      </>
    );	
	    
};
