import React,{useEffect,useState} from 'react'
import {Spinner} from '../spinner/Spinner';
import {Commaseparator} from '../utils/Commaseparator'
import {SearchListdata} from '../utils/Searchdata'
import Papa from 'papaparse'
export const SessionData = (props) => {
  const [dataCount,SetDataCount] = useState([]);
  const [excelData,SetExcelData] = useState([])
  const [status,SetStatus] = useState(true);
  var file_name = '';

  useEffect(() => {
    SetStatus(true)

    var csvFilePath = process.env.PUBLIC_URL+`/${props.match.params.year}.csv`;
    Papa.parse(csvFilePath, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: updateData
    });

    function updateData(result) {
      const data = result.data;
      SetDataCount(data);
    }

  },[props.match.params.name])

	return(
		<>
    <Spinner visible = {status} />
    <h1>{props.match.params.year} I-RIM Conference</h1>
    <h2>Table of Contents</h2>
			<table>  
        <tbody>
          <SearchListdata data = {dataCount} name = {props.match.params.name} />
        </tbody>
      </table>
      
      <br />
		</>
	)
}