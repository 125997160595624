import React,{useEffect,useState} from 'react'
import {Spinner} from '../spinner/Spinner';
import {Pdfseparator} from '../utils/Pdfseparator'
import Papa from 'papaparse'


export const Contentpage = (props) => {
  const [data,SetData] = useState([]);
  const [excelData,SetExcelData] = useState([])
  const [linkData,SetLinkData] = useState('');
  const [pagecount,SetPageCount] = useState(1);
  const [pagination,SetPagination] = useState(10);
  const [status,SetStatus] = useState(true);
  var file_name = '';

  useEffect(() => {
    SetStatus(true)

    var csvFilePath = process.env.PUBLIC_URL+`/${props.match.params.year}.csv`;
    Papa.parse(csvFilePath, {
      header: true,
      download: true,
      skipEmptyLines: true,
      complete: updateData
    });

    function updateData(result) {
      const data = result.data;
      SetExcelData(data);
    }

  },[props.match.params.year])

  
	return(
		<>
    <Spinner visible = {status} />
    <h1>{props.match.params.year} I-RIM Conference</h1>
    <h2>Table of Contents</h2>
			<table>  
        <tbody>

          {excelData.map((contentDetails, index) => (
              <>
              <tr key={index}>
                <td width={500}>
                  <a href={"/content/details/"+contentDetails.year+"/"+contentDetails.rec_id}>{contentDetails.title}</a>
                  <br />
                  <i>
                    {contentDetails.creators}
                  </i><br />
                </td>
                <td valign="top">
                <Pdfseparator file = {contentDetails.pdf}  />
                
                </td>
              </tr> 
              <tr>
                <td>&nbsp;</td>
              </tr>
              </>
          ))}
    
        </tbody>
      </table>
      
      <br />
		</>
	)
}