import React from 'react'
import ReactPlayer from 'react-player/youtube'

export const Commaseparator = (props) => {
	var file_name = props.file;
	var nameArr = canSplit(file_name, ',') === true ? file_name.split(',') : (file_name);
	console.log(nameArr)
	return(
		<>
			

			{Array.isArray(nameArr) ? <p>  
				<b>Videos:</b> 
			</p> : file_name != '' ? <p>  
				<b>Videos:</b> 
			</p> : ''}

			{Array.isArray(nameArr) ? nameArr.map((contentDetails, index) => (
			 	<ReactPlayer url={contentDetails} config={{
													 youtube: {
													      playerVars: { showinfo: 1 }
													    },
													  }} className='react-player' />
			)) : (file_name != null ? <ReactPlayer url={nameArr} config={{
													 youtube: {
													      playerVars: { showinfo: 1 }
													    },
													  }} className='react-player' /> : '')}
		</>

	)
}

var canSplit = function(str, token){
    return (str || '').split(token).length > 1;         
}