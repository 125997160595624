import React from 'react'
import {NavLink} from 'react-router-dom'

export const Removeduplicate = (props) => {
	var file_name = props.file;
	const arr1 = getUniqueListBy(file_name, 'conference_session')
	return (
		<>
			{arr1.map((contentDetails, index) => (

				<>
	              <tr key={index}>
	                <td width={500}>
	                  <a href={"/session/"+props.year+"/"+contentDetails.conference_session}>{contentDetails.conference_session}</a>
	                </td>
	              </tr> 
	              <tr>
	                <td>&nbsp;</td>
	              </tr>
              </>
			))}
		</>
	)
}


const getUniqueListBy = function(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}
