import React from 'react'

export const Pdfseparator = (props) => {
	var file_name = props.file;
	var pageNumber = props.pageno;
	var sepRecID = props.recID;
	var nameArr = canSplit(file_name, ',') === true ? file_name.split(',') : file_name;
	var pageNum = canSplit(pageNumber, ',') === true ? pageNumber.split(',') : pageNumber;
	var rec_id = canSplit(sepRecID, '--') === true ? sepRecID.split('--') : sepRecID;
	var year = props.year;
	return(

		<>
			{Array.isArray(nameArr) && Array.isArray(pageNum) && Array.isArray(rec_id) ? nameArr.map((files,index) => (
				<><a href={"/content/details/"+year+"/"+rec_id[index]} target="_blank">{pageNum[index]}</a><a href={files} key={index} style={{marginRight: 10}}>{files.includes("pdf") === true ?  <img src={process.env.PUBLIC_URL+"/icon-pdf.png"} /> : ''}</a></>
			)) : (file_name == null ? '' :<><a href={"/content/details/"+year+"/"+rec_id} target="_blank">{pageNum}</a> <a href={nameArr} style={{marginRight: 10}}>{nameArr.includes("pdf") === true ? <img src={process.env.PUBLIC_URL+"/icon-pdf.png"} /> : ''}</a></>) }
			<br />
			
		</>

	)
}

export const PdfseparatorOnly = (props) => {
	var file_name = props.file;
	var nameArr = canSplit(file_name, ',') === true ? file_name.split(',') : file_name;
	return(

		<>
			{Array.isArray(nameArr) ? nameArr.map((files,index) => (
				<><a href={files} key={index} style={{marginRight: 10}} target="_blank">{files.includes("pdf") === true ?  <img src={process.env.PUBLIC_URL+"/icon-pdf.png"} /> : ''}</a></>
			)) : (file_name == null ? '' :<><a href={nameArr} style={{marginRight: 10}} target="_blank">{nameArr.includes("pdf") === true ? <img src={process.env.PUBLIC_URL+"/icon-pdf.png"} /> : ''}</a></>) }
			
			
		</>

	)
}


var canSplit = function(str, token){
    return (str || '').split(token).length > 1;         
}