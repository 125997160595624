import React, { useEffect } from 'react'

export const Homepage = () => {
	return (
		<>
			<p>
				<b><a href="/irim3d/2023">2023 I-RIM Conference</a></b>
				<br />
				October 20-22, 2023<br />
				Rome, Italy<br />
				Editors: Gianluca Antonelli, Giuseppe Carbone, Arianna Menciassi, Silvia Rossi<br />
				ISBN: 9788894580549<br />
				<a href="https://i-rim.it/it/conferenza-i-rim-2023/">Conference web site</a>
			</p>
			<p>
				<b><a href="/irim3d/2022">2022 I-RIM Conference</a></b>
				<br />
				VOL. 1: Editors: Antonio Frisoli, Federica Pascucci<br />
				October 7-9, 2022<br />
				Rome, Italy<br /> <br />

				VOL.2: Editors: Paolo Rocco, Lorenzo Molinari Tosatti<br />
				October 13-14, 2022<br />
				Milan, Italy<br />
				ISBN: 978-88-945805-3-2<br />
				<a href="https://i-rim.it/en/program/">Conference web site</a>
				<br />
			</p>

			<p>
				<b><a href="/irim3d/2021">2021 I-RIM Conference</a></b>
				<br />
				October 8-10, 2021 <br />
				Rome, Italy (Virtual)<br />
				Editors: Christian Cipriani, Maria Pia Fanti, Monica Malvezzi, Angelika Peer<br />
				ISBN: 9788894580525<br />
				<a href="https://i-rim.it/en/program/">Conference web site</a>
				<br />
			</p>

			<p>
				<b><a href="/irim3d/2020">2020 I-RIM Conference</a></b>
				<br />
				December 10-12, 2020 <br />
				Rome, Italy (Virtual)<br />
				Editors: Benedetto Allotta, Maria Chiara Carrozza, Emanuele Menegatti and Giuseppe Oriolo<br />
				ISBN: 9788894580518<br />
				<a href="https://i-rim.it/en/program/">Conference web site</a>
				<br />
			</p>

			<p>
				<b><a href="/irim3d/2019">2019 I-RIM Conference</a></b>
				<br />
				October 18-20, 2019<br />
				Rome, Italy<br />
				Editors: Barbara Caputo, Cecilia Laschi and Domenico Prattichizzo<br />
				ISBN: 9788894580501<br />
				<a href="https://i-rim.it/en/pitches-and-posters-2/#posters">Conference web site</a><br />
			</p>
		</>

	)
}